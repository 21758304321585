var site = site || {};

site.account = site.account || {};

(function ($, generic) {
  var titleTemplate;
  var titleVars = {};
  var $titles = $();
  var titleClass = 'signin-block__authed__nav__title';
  var rClass = titleClass + '--rendered';

  function _renderTitles() {
    $titles.html(Mustache.render(titleTemplate, titleVars)).addClass(rClass);
  }

  function _setUserName() {
    var $signedUserName = $('.js-signed-in-user-name');
    var prefix = $signedUserName.data('user-prefix');
    var suffix = $signedUserName.data('user-suffix');

    if (titleVars.first_name !== null) {
      $signedUserName.html(prefix + ' ' + titleVars.first_name + ' ' + suffix);
    }
  }

  Drupal.behaviors.signinBlock = {
    attach: function (context) {
      var $blocks = $('.js-signin-block', context);

      $titles = $titles.add($('.' + titleClass, $blocks));
      var firstName = site.userInfoCookie.getValue('first_name');
      var formLocation = '/checkout/includes/forgot_pure_privilege_number_popup.tmpl';

      if (firstName) {
        titleVars.first_name = firstName;
      }
      if (!titleTemplate) {
        titleTemplate = $titles.first().html();
      }
      _renderTitles();
      _setUserName();

      $blocks.each(function () {
        var $block = $(this);
        var $toggleTrigger = $('.js-signin-block-toggle', this);
        var $ppTrigger = $('.js-pure-privilege-form-toggle', this);
        var $ppForm = $('.js-pure-privilege-form', this);
        var $registerForm = $('.signin-block__form--register', this);
        var $toggleRegisterPass = $('.js-show-password', $registerForm);
        var $registerPass = $('.signin-block__field--password', $registerForm);
        var showPass = false;

        function togglePassword() {
          showPass = !showPass;
          $registerPass.get(0).type = showPass ? 'text' : 'password';
          $block.toggleClass('signin-block--show-password', showPass);
        }

        $toggleRegisterPass.on('click', function (event) {
          togglePassword();
        });

        $registerForm.on('submit', function () {
          if ($.cookie('csrftoken')) {
            $('.js-pp-fieldset').append('<input type="hidden" name="_TOKEN" value="' + $.cookie('csrftoken') + '" />');
          }
          if (showPass) {
            togglePassword();
          }
        });

        $toggleTrigger.on('click', function (event) {
          event.preventDefault();
          $block.toggleClass('signin-block--signin');
        });

        $ppTrigger.val([]).on('change', function () {
          if ($ppForm.hasClass('hidden')) {
            $ppForm.removeClass('hidden');
          } else {
            $ppForm.addClass('hidden');
            // reset form to empty values
            $ppForm.find('input[type="text"], select').val([]).trigger('select.enabled');
          }
        });

        if (($ppTrigger[0] !== undefined && $ppTrigger[0].hasAttribute('checked')) || $ppForm.find('input[type="text"]').val() && $ppForm.hasClass('hidden')) {
          $ppForm.removeClass('hidden');
          $ppTrigger.attr('checked', 'checked');
        }

        $ppTrigger.find('a').click(function (e) {
          e.stopPropagation();
        });
      });

      if (generic.cookie('USER_RECOGNIZED')) {
        $blocks.addClass('signin-block--signin');
      }
      $(document).off('cbox_complete').on('cbox_complete', function () {
        if ($('#cboxContent').find('.tiny-pp-overlay, .pure-priv-card-info').length !== 0) {
          $('#cboxOverlay').addClass('js-pure-privilege-forgot-overlay pure-privilege-forgot-overlay');
          var $container = $('.js-pure-privilege-forgot-overlay');

          if (!$container.length) {
            return;
          }
          initPPForgotLinks();
          initForgotForm();
        }
      });

      function launchOverlay(hrefLocation) {
        generic.overlay.launch({
          href: hrefLocation,
          cssStyle: {
            width: '530px',
            height: 'auto'
          },
          className: 'js-pure-privilege-forgot-overlay pure-privilege-forgot-overlay'
        });
      }

      function initPPForgotLinks() {
        var $forgotTriggers = $('a.js-privilege-forgot-number-trigger');

        $forgotTriggers.off('click').on('click', function (event, string) {
          event.preventDefault();
          if (string === 'pp-number-notfound') {
            formLocation = '/checkout/includes/forgot_pp_number.tmpl';
          }
          launchOverlay(formLocation);
        });
      }

      function initForgotForm($container) {
        var $form = $('.pure-privilege-number-forgot-popup-page form', $container);

        $('.forgot_pp_submit', $form).off().on('click', function (event) {
          event.preventDefault();
          $.ajax({
            type: 'POST',
            url: formLocation,
            data: $form.serialize(),
            success: function (response) {
              var $errors = $(response).find('#form--errors--pure_privilege_number_send');
              var $response_page = $(response).find('.js-pp-response-page');

              if ($errors.children('.s').length || $errors.children('.server').length) {
                $('.error_messages', $container).replaceWith($errors);
                initPPForgotLinks();
                initForgotForm($container);
              } else {
                $('.pure-privilege-number-forgot-popup-page', $container).replaceWith($response_page);
                initOverlayClose($container);
                initPPRetryLinks($container);
                initPPNumberButton($container);
              }
            }
          });
        });
      }

      function initOverlayClose($container) {
        $('.js-pp-success-btn', $container).click(function () {
          generic.overlay.hide();
        });
      }

      function initPPRetryLinks($container) {
        $('.js-pp-retry-btn', $container).click(function () {
          initPPForgotLinks();
          launchOverlay(formLocation);
        });
      }

      function initPPNumberButton($container) {
        $('.js-pp-number-button', $container).click(function () {
          initPPForgotLinks();
          formLocation = '/checkout/includes/forgot_pp_number.tmpl';
          launchOverlay(formLocation);
        });
      }
      var $forgotPassword = $('.js-forgot-password', context);
      var $signinEmailAddress = $('.js-return-user-email-gnav', context);

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $signinEmailAddress,
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }
      site.account.initPasswordValidationTooltip(context);
    }
  };

  $(document).on('user.loaded', function (event, val) {
  // This is brittle but there's no other way to tell from the data we get.
    if (!$titles.length) {
      $titles = $('.' + titleClass);
    }
    if (!titleTemplate) {
      titleTemplate = $titles.first().html();
    }
    titleVars.first_name = val.first_name;

    // If user is ever signed in, permanently store a "USER_REC" cookie. This
    // only expires once a year.
    if (val.signed_in) {
      generic.cookie('USER_RECOGNIZED', 1, {
        path: '/',
        expires: 365
      });
      $('body').addClass('signed-in');
    }

    _renderTitles();
    _setUserName();
  });
  $(document).ready(function () {
    _toggleSmsInput();
  });

  function _toggleSmsInput() {
    var wrapper = $('.sms_promo_mobile_number'), checkbox = $('input.sms_options'), value = '';

    if (wrapper.length > 0) {
      var input = $('input.sms_input', wrapper);

      wrapper[checkbox.is(':checked') ? 'show' : 'hide']();
      checkbox.unbind('change.sms').bind('change.sms', function () {
        if ($(this).is(':checked')) {
          // input.val(value);
          wrapper.fadeIn();
        } else {
          value = input.val();
          // input.val('');
          wrapper.fadeOut();
        }
      });
    }
  }
})(jQuery, window.generic || {});

/**
    * One-time call to collect specific DOM elements on the page.
    * @methodOf site.account
*/
site.account.getPassDOMNodes = function () {
  site.account.passwordInfo = $('.password-field__info');
};

site.account.validatePasswordRules = function () {
  site.account.passwordInfo.removeClass('hidden');
  var $input = $(this),
    value = this.value,
    $rulesItems = $input.closest('.form-item').find('.password-field__rules').find('li'),
    rules = {
      '1 lower case character': /[a-z]/,
      '1 upper case character': /[A-Z]/,
      '1 number': /[0-9]/,
      '8 characters minimum': /.{8,}/
    };

  // mark the input field if it has any value
  this.classList.toggle('hasValue', this.value);
  // iterate all the rules and validate them
  $.each($rulesItems, function (i, elm) {
    var valid,
      ruleName = elm.innerText.toLowerCase();

    if (rules[ruleName]) {
      valid = new RegExp(rules[ruleName]).test(value);
      elm.classList.toggle('pass', valid);
    }
  });
};

site.account.initPasswordValidationTooltip = function (context) {
  var $passwordField = $('.password-field');

  site.account.getPassDOMNodes();
  if (matchMedia('screen and (max-width:768px)').matches || site.client.isMobile) {
    site.account.passwordInfo.removeClass('hidden');
  }
  if ($(window).width() == 1024 && $('.js-signin-block', context).hasClass('signin-overlay')) {
    site.account.passwordInfo.removeClass('hidden');
  }
  $('input[type="text"],input[type="password"]', $passwordField).on('input focus', site.account.validatePasswordRules);
  $('input[type="text"],input[type="password"]', $passwordField).bind('blur', function () {
    site.account.passwordInfo.addClass('hidden');
    if (matchMedia('screen and (max-width:768px)').matches || site.client.isMobile) {
      site.account.passwordInfo.removeClass('hidden');
    }
    if ($(window).width() == 1024 && $('.js-signin-block', context).hasClass('signin-overlay')) {
      site.account.passwordInfo.removeClass('hidden');
    }
  });
};
